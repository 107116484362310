<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl  text-left font-extrabold ml-4 mt-3 mr-8">
        Settings
      </h1>
    </div>
    <div class="w-full flex">
      <SettingsMenu class="mx-2" />
    </div>
  </div>
</template>

<script>
import SettingsMenu from '@/components/SettingsMenu';

export default {
  name: 'Function',
  components: {
    SettingsMenu,
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
.button:focus {
  outline: none;
}
</style>
