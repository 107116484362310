<template>
  <div class="flex h-full w-full mt-3 mb-6 overflow-x-hidden">
    <Card class="menu flex flex-col justify-center">
      <div class="searchCard">
        <SearchInput placeholder="Search" class="search" />
      </div>

      <SettingsMenuCard
        v-for="(n, index) in cards.filter(item => item.show === true)"
        :key="index"
        :name="n.name"
        :sub="n.sub"
        :link="n.href"
        :icon="n.icon"
        :color="n.color"
      />
      <!-- <SettingsMenuCard /> -->
    </Card>
    <Card class="w-full mx-3 relative overflow-x-hidden">
      <router-view />
    </Card>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Card from "@/components/Card";
import SettingsMenuCard from "@/components/SettingsMenuCard";

export default {
  name: "SettingsMenu",
  components: {
    Card,
    SearchInput,
    SettingsMenuCard
  },
  data() {
    return {
      cards: [
        {
          name: "Profile",
          icon: "user",
          sub: "Company Details and HR Admin",
          color: "blueCrayola",
          href: "Profile",
          show: true
        },
        {
          name: "Security",
          icon: "padlock",
          sub: "Password, 2FA",
          color: "green-500",
          href: "SecuritySettings",
          show: true
        },
        {
          name: "Roles and Privileges",
          icon: "id_card",
          sub: "User roles and app access controls",
          color: "romanSilver",
          href: "RolesAndPrivileges",
          show: true
        },
        {
          name: "Admins",
          icon: "group",
          sub: "Admin directory",
          color: "carrotOrange",
          href: "Admin",
          show: true
        },
        // {
        //   name: "Location and Tax", icon: "map", sub: "Office locations and Tax information", color: "lite", href: null,
        // },
        {
          name: "Bank Accounts",
          icon: "cash",
          sub: "Virtual NUBAN Accounts",
          color: "flame",
          href: "BankAccounts",
          show: true
        },
        {
          name: "Pay Settings",
          icon: "creditCard",
          sub: "Earnings, Deductions and Compliance",
          color: "optima",
          href: "PaySettings",
          show: true
        },
        {
          name: "Compensation",
          icon: "icon-money-2",
          sub: "Pay Grade, Milestones and Preferences",
          color: "mediumSeaGreen",
          href: "CompensationSettings",
          show: true
        },
        {
          name: "Employee Management",
          icon: "group",
          sub: "Setup Confirmation",
          color: "desire",
          href: "EmployeeManagementSettings",
          show: true
        },
        {
          name: "Approval Flow",
          icon: "approvalHierachy",
          sub: "Approval Workflow Structure",
          color: "queenPink",
          href: "Approval",
          show: true
        },
        {
          name: "Signatories",
          icon: "signFile",
          sub: "Authorized Signatories",
          color: "purple-500",
          href: null,
          show: process.env.NODE_ENV === "development"
        },
        {
          name: "Leave Management",
          icon: "cancelNotification",
          sub: "Leave Policy Settings",
          color: "jet",
          href: "LeaveManagementSettings",
          show: true
        },
        {
          name: "Performance",
          icon: "meter",
          sub: "Performance Templates, Ratings etc",
          color: "mediumSeaGreen",
          href: "PerformanceSettings",
          show: true
        },
        {
          name: "Documents",
          icon: "file",
          sub: "Visibility & Capacity Management",
          color: "ally",
          href: null,
          show: process.env.NODE_ENV === "development"
        },
        {
          name: "Audit Log",
          icon: "file",
          sub: "Auditing and computations",
          color: "ally",
          href: 'AuditLog',
          show: process.env.NODE_ENV === "development"
        },
        {
          name: "Time Tracking",
          icon: "icon-timer-2",
          sub: "Work Schedule and Preferences",
          color: "mediumSeaGreen",
          href: 'TimeTrackingSettings',
          show: process.env.NODE_ENV === "development"
        },
        {
          name: "Appearance",
          icon: "chart",
          sub: "Theme Setting",
          color: "accentSquash",
          href: 'Appearance',
          show: true
        }
      ]
    };
  }
};
</script>

<style scoped>
.menu {
  width: 308px;
  height: max-content;
}
.search {
  width: 292px;
}
.searchCard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  height: 56px;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
}
</style>
