<template>
  <div
    class="flex flex-col px-2 container cursor-pointer"
    :class="[
      link === activeLink ? 'activeSettings-link' : ''
    ]"
    @click="change"
  >
    <div class="borderLine" />
    <div class="flex mt-3 ml-3 items-center">
      <icon
        :icon-name="icon"
        size="s"
        :class-name="`text-${color} mr-3`"
      />
      <div class="flex flex-col">
        <p class="font-semibold">
          {{ name }}
        </p>
        <p class="text-xs">
          {{ sub }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  components: { Icon },
  props: ['name', 'sub', 'icon', 'color', 'link'],
  data() {
    return {
      activeLink: this.$router.currentRoute.name,
    };
  },
  methods: {
    change() {
      return this.link ? this.$router.push({ name: this.link }) : null;
    },
  },
  watch: {
    $route(to) {
      const link = to.name.toString();
      this.activeLink = link;
    },
  },

};
</script>

<style scoped>
.container{
    height: 64px;
}
.borderLine{
border-top: 0.5px solid #878E99;
opacity: 0.3;
width: 100%;
}
.activeSettings-link{
  border-right: 5px solid #F15A29;
}
</style>
